const colorMap = {
  WHITE: "#FFFFFF",
  BROWN: "#996633",
  LIGHT_BROWN: "#c49566",
  LIGHT_RED: "#FF0000",
  RED: "#cc0000",
  YELLOW: "#ffff00",
  LIGHT_YELLOW: "#ffff8f",
  LIGHT_BLUE: "#71D3FC",
  BLUE: "#0000FF",
  LIGHT_ORANGE: "#ffc04d",
  ORANGE: "#ff9900",
  GREEN: "#00ff00",
  LIGHT_GREEN: "#a3ffa3",
  NEON_GREEN: "#39FF14",
  BLACK: "#000000",
  GREY: "#808080",
  LIGHT_GREY: "#c4c0c0",
  VIOLET: "#9900cc",
  LIGHT_VIOLET: "#da89f5",
  PINK: "#ff99cc",
  LIGHT_PINK: "#ffbdde",
  TURQUOISE: "#00ffff",
  LIGHT_TURQUOISE: "#a8ffff",
  TRANSPARENT: "#f9f9f9",
};

export default colorMap;
